import React, { useEffect, useState } from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import { fetchBusinesses, fetchLocations, fetchPricing } from "../state";
import { Layout, SinglePricing } from "../components";
import { BallBeat } from "react-pure-loaders";

import { AgenciesSection as AddOrUpdatePricingSection } from "../style";
import { navigate } from "gatsby-link";
// import { BallBeat } from "react-pure-loaders";

function AddOrUpdatePricing({
  location = {},
  businessesData,
  fetchBusinesses,
  locationData,
  fetchLocations,
}) {
  const dispatch = useDispatch();

  const loginData = useSelector((state) => state.auth.user);
  const loginBool = Boolean(loginData.portalUserId);
  var businessesOptions = businessesData
    .filter((business) => {
      return (
        business.isActive === true &&
        business.isShowForRetail === true &&
        business.isApi === false
      );
    })
    .map((type) => (
      <option value={type.id} key={type.id} label={type.businessName}>
        {" "}
        {type.businessName}{" "}
      </option>
    ));
  var locationOptions = locationData.map((type) => (
    <option value={type.id} key={type.id} label={type.name}>
      {" "}
      {type.name}{" "}
    </option>
  ));
  useEffect(() => {
    if (!loginBool) {
      navigate("/login");
    }
    if (businessesData.length === 0) {
      fetchBusinesses();
    }
    if (locationData.length === 0) {
      fetchLocations();
    }
  }, [fetchBusinesses, fetchLocations]);
  return (
    <div>
      <Layout>
        <AddOrUpdatePricingSection>
          <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
            <p
              onClick={() => {
                navigate("/pricing");
              }}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: "0.7rem",
                marginBottom: "1rem",
              }}
            >
              Product Pricing Table
            </p>
            <p
              style={{
                // cursor: "pointer",
                fontSize: "0.9rem",
                marginBottom: ".9rem",
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            >
              {" "}
              &#8827;
            </p>
            {location.state && location.state.data && location.state.data.id ? (
              <p
                style={{
                  // cursor: "pointer",
                  fontSize: "0.7rem",
                  marginBottom: "1rem",
                }}
              >
                Pricing Id:{" "}
                {location.state && location.state.data && location.state.data.id
                  ? location.state.data.id
                  : " "}
              </p>
            ) : (
              <p
                style={{
                  // cursor: "pointer",
                  fontSize: "0.7rem",
                  marginBottom: "1rem",
                }}
              >
                New Product Pricing
              </p>
            )}
          </div>
          {location.state && location.state.data && location.state.data.id ? (
            <h1>
              Product Pricing Details of{" "}
              <span
                style={{
                  backgroundColor: "yellow",
                }}
              >
                #
                {location.state && location.state.data && location.state.data.id
                  ? location.state.data.id
                  : " "}
              </span>
            </h1>
          ) : (
            <h1>Add new product pricing</h1>
          )}
          {businessesData.length > 0 && locationData.length > 0 ? (
            <SinglePricing
              pricingData={location.state && location.state.data}
              businessesOptions={businessesOptions}
              locationsOptions={locationOptions}
              businesses={businessesData}
              locations={locationData}
            />
          ) : (
            <h3>
              {" "}
              <span>
                <BallBeat
                  color={"#123abc"}
                  loading={
                    businessesData.length === 0 && locationData.length === 0
                  }
                />
              </span>{" "}
            </h3>
          )}
        </AddOrUpdatePricingSection>
      </Layout>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    businessesData: state.vendors.businesses,
    locationData: state.vendors.locations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBusinesses: () => dispatch(fetchBusinesses()),
    fetchLocations: () => dispatch(fetchLocations()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrUpdatePricing);
